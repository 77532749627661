import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Nav } from 'reactstrap';
import { useIntl } from 'react-intl';
import { ReactComponent as IconCustomer } from '@whitelabel/media/icons/global/icon-16-customer.svg';
import { ReactComponent as IconCustomerLarge } from '@whitelabel/media/icons/global/icon-40-customer.svg';
import { ReactComponent as IconChevronRight } from '@whitelabel/media/icons/small/chevron-right.svg';
import logo from '@whitelabel/media/logo.svg';
import { ICustomer } from '@whitelabel/helpers/types';
import { INavItem } from '@whitelabel/xcover-www-frontend/src/helpers/types';
import {
  GlobalStyle,
  StyledDesktopGlobalNavBar,
  StyledNavBar,
  StyledNavBarToggler,
  StyledNavBox,
  StyledMenu,
  StyledNavSeperator,
  StyledPrimaryNavItems,
  StyledNavCustomer,
  StyledNavCustomerDetailsWrapper,
  StyledNavCustomerDetails,
  StyledNavCustomerIcon,
  StyledNavCustomerName,
  StyledNavCustomerEmail,
  StyledNavCustomerItems,
  StyledNavIcon,
  StyledBrandWrapper,
  StyledMenuGlobalNavItems,
  StyledNavCustomButton,
  StyledMobileTabletProtectionItem,
  StyledNavButton,
  StyledMobileTabletNavButtonWrapper,
  StyledCustomNavItem,
  StyledMobilePrimaryNavItems,
  StyledDesktopPrimaryNavItems,
} from '@whitelabel/xcover-www-frontend/src/containers/layout/NavBar/styledNavBar';
import { getGlobalNavItems } from '@whitelabel/xcover-www-frontend/src/containers/layout/NavBar//helpers';
import { PoweredByBrand } from '@whitelabel/xcover-www-frontend/src/components/NavBar';
import { SITE_NAME, ENABLE_QUOTE_AND_BOOKING } from '@whitelabel/xcover-shared/helpers/constants';
import MenuDropdown from '@whitelabel/xcover-shared/components/NavBar/MenuDropdown';
import { IGetStyledDropdownItemProps } from '@whitelabel/xcover-shared/helpers/types';
import LinkTo from '@whitelabel/xcover-shared/components/LinkTo';
import LocaleModal from '../LocaleModal';
import { ACCOUNT_NAV_ITEMS } from '../../helpers/constants';
import Brand from './Brand';
import NavItem from './NavItem';
import SecondaryNav from './SecondaryNav';
import GlobalCustomerDropdown from './GlobalCustomerDropdown';
import { StyledDummyDiv } from './styledNavBar';
import messages from './messages';
import {
  customerNavItems,
  protectionNavItems,
  globalNavAccount,
  globalNavHelp,
  globalNavLogin,
  navProtection,
  publicNavItems,
  customerDesktopNavItems,
} from './navItems';

interface INavBarProps {
  customer?: ICustomer;
  cobrand?: {
    title: string;
    logo: string;
  };
  items?: INavItem[];
  size?: 'lg';
  background?: boolean;
  hideGlobalNav?: boolean;
  checkingSession?: boolean;
  hasLoggedIn: boolean;
}

const NavBar = ({
  items = [],
  customer,
  cobrand,
  size,
  background = false,
  hideGlobalNav = false,
  checkingSession,
  hasLoggedIn,
}: INavBarProps): JSX.Element => {
  const intl = useIntl();
  const brand = cobrand ? { ...cobrand, size: 'lg' as const, link: false } : { title: SITE_NAME, logo, link: true };
  const [isOpen, setOpen] = useState(false);
  const toggleOpen = () => setOpen(!isOpen);
  const closeNav = () => {
    if (isOpen) {
      toggleOpen();
    }
  };
  const [isLocaleModalOpen, setIsLocaleModalOpen] = useState(false);
  const toggleLocaleModal = () => {
    closeNav();
    setIsLocaleModalOpen((prev) => !prev);
  };

  const [isSecondaryNavOpen, setIsSecondaryNavOpenOpen] = useState(false);
  const toggleSecondaryNav = () => setIsSecondaryNavOpenOpen((prev) => !prev);

  const toggleNav = () => {
    toggleOpen();
    if (isSecondaryNavOpen) {
      toggleSecondaryNav();
    }
  };

  const hasNavItems = hasLoggedIn ? true : !!items?.length;

  const renderNavItems = (navItems: INavItem[], hideOnMobileAndTablet?: boolean) =>
    navItems.map((item) => (
      <NavItem key={item.key} item={item} onClick={closeNav} hideOnMobileAndTablet={hideOnMobileAndTablet} />
    ));

  const getMobileNavItems = () => {
    let navItems: INavItem[] = [...items];
    if (hasLoggedIn) {
      navItems = customerNavItems;
    }
    return renderNavItems(navItems);
  };

  const getDesktopNavItems = () => {
    let navItems: INavItem[] = [...items];
    if (hasLoggedIn) {
      navItems = ACCOUNT_NAV_ITEMS;
    }
    return renderNavItems(navItems, hasLoggedIn);
  };

  const renderCustomerItems = () => (
    <StyledNavCustomer>
      {customer?.firstName !== 'N/A' ? (
        <StyledNavCustomerDetailsWrapper>
          <StyledNavCustomerIcon as={IconCustomerLarge} />
          <StyledNavCustomerDetails>
            <StyledNavCustomerName>{`${customer?.firstName} ${customer?.lastName}`}</StyledNavCustomerName>
            <StyledNavCustomerEmail>{customer?.email}</StyledNavCustomerEmail>
          </StyledNavCustomerDetails>
        </StyledNavCustomerDetailsWrapper>
      ) : (
        <StyledNavCustomerDetailsWrapper>
          <StyledNavCustomerIcon $small as={IconCustomer} />
          <StyledNavCustomerEmail>{customer.email}</StyledNavCustomerEmail>
        </StyledNavCustomerDetailsWrapper>
      )}
      <StyledNavCustomerItems>{getMobileNavItems()}</StyledNavCustomerItems>

      <StyledNavSeperator />
    </StyledNavCustomer>
  );

  const renderQuoteButton = (hideOnMobileAndTablet?: boolean) => (
    <StyledNavButton
      color="primary"
      data-analytics="navGetQuote"
      href={`/${intl.locale}/get-quote/product`}
      $hideOnMobileAndTablet={hideOnMobileAndTablet}
    >
      {intl.formatMessage(messages.quoteButton)}
    </StyledNavButton>
  );
  const renderMobileButtons = () =>
    (!hasLoggedIn || ENABLE_QUOTE_AND_BOOKING) && (
      <StyledMobileTabletNavButtonWrapper $fixed={isOpen}>
        {ENABLE_QUOTE_AND_BOOKING && renderQuoteButton()}
        {!hasLoggedIn && (
          <StyledNavButton
            tag="a"
            id="nav-login-mobile-button"
            color="primary"
            data-analytics="navLoginMobile"
            href={`/${intl.locale}/login`}
          >
            {intl.formatMessage(messages.loginLink)}
          </StyledNavButton>
        )}
      </StyledMobileTabletNavButtonWrapper>
    );

  const renderAccountLink = () => (
    <GlobalCustomerDropdown customerItems={customerDesktopNavItems} customer={customer} item={globalNavAccount} />
  );

  const getStyledDropdownItemProps: IGetStyledDropdownItemProps = (locale, path) => ({
    tag: LinkTo,
    href: `/${locale}${path}`,
  });

  const renderProtectionLink = () => (
    <>
      <StyledMobileTabletProtectionItem>
        <button onClick={toggleSecondaryNav} type="button">
          {intl.formatMessage(messages.protectionLink)}
          <StyledNavIcon as={IconChevronRight} />
        </button>
      </StyledMobileTabletProtectionItem>
      <MenuDropdown
        items={protectionNavItems}
        item={navProtection}
        getStyledDropdownItemProps={getStyledDropdownItemProps}
      />
    </>
  );

  const renderGlobalNavItems = () =>
    getGlobalNavItems({ NavItem, globalNavHelp, closeNav, toggleLocaleModal, locale: intl.locale });

  const renderUnAuthedNavItems = () => (
    <>
      {renderProtectionLink()}
      {publicNavItems.map((item) => (
        <NavItem key={item.key} item={item} onClick={closeNav} />
      ))}
      <StyledCustomNavItem>
        <StyledNavCustomButton
          tag="a"
          $rtl={intl.bidi}
          buttonType="tertiary"
          data-analytics="navbarappsForMerchants"
          data-test-id="NavItem-link"
          href="https://covergenius.com/retail/"
          onClick={closeNav}
        >
          {intl.formatMessage(messages.appsForMerchants)}
        </StyledNavCustomButton>
      </StyledCustomNavItem>
    </>
  );

  function renderPrimaryNavItems() {
    if (hasLoggedIn) {
      return (
        <>
          <StyledMobilePrimaryNavItems $rtl={intl.bidi}>
            {renderCustomerItems()}
            {getDesktopNavItems()}
          </StyledMobilePrimaryNavItems>
          <StyledDesktopPrimaryNavItems $rtl={intl.bidi}>{getDesktopNavItems()}</StyledDesktopPrimaryNavItems>
        </>
      );
    }
    return (
      <StyledPrimaryNavItems $rtl={intl.bidi}>
        {renderUnAuthedNavItems()}
        {getDesktopNavItems()}
      </StyledPrimaryNavItems>
    );
  }

  return (
    <div>
      {!hideGlobalNav && (
        <StyledDesktopGlobalNavBar container>
          {!hasLoggedIn && checkingSession ? (
            <StyledDummyDiv />
          ) : (
            <Nav>
              {renderGlobalNavItems()}
              {hasLoggedIn ? renderAccountLink() : <NavItem item={globalNavLogin} onClick={closeNav} />}
            </Nav>
          )}
        </StyledDesktopGlobalNavBar>
      )}

      <StyledNavBar
        id="NavBar"
        size={size}
        dark={isOpen}
        color={background ? 'white' : undefined}
        data-test-id="NavBar"
      >
        {cobrand ? (
          <StyledBrandWrapper $cobrand>
            <Brand {...brand} locale={intl.locale} hasLoggedIn={hasLoggedIn} />
            <PoweredByBrand />
          </StyledBrandWrapper>
        ) : (
          <StyledBrandWrapper>
            <Brand {...brand} locale={intl.locale} hasLoggedIn={hasLoggedIn} />
            {hasNavItems && <StyledNavBarToggler $active={isOpen} onClick={toggleNav} data-test-id="NavBar-toggler" />}
          </StyledBrandWrapper>
        )}

        {hasNavItems && (
          <StyledMenu $rtl={intl.bidi} $isOpen={isOpen} $isSecondaryNavOpen={isSecondaryNavOpen}>
            <div className="StyledMenu-container">
              <GlobalStyle fixed={isOpen} />
              <StyledNavBox data-test-id="NavBar-nav">
                {(hasLoggedIn || !checkingSession) && renderPrimaryNavItems()}

                {!hasLoggedIn && <StyledNavSeperator />}
                {/* mobile globalNav items */}

                <StyledMenuGlobalNavItems>{renderGlobalNavItems()}</StyledMenuGlobalNavItems>
                {renderMobileButtons()}
                {ENABLE_QUOTE_AND_BOOKING && renderQuoteButton(true)}
              </StyledNavBox>

              <StyledNavBox $secondary={isOpen} $hideOnDesktop data-test-id="NavBar-subNav">
                <StyledPrimaryNavItems as="div" $rtl={intl.bidi} $secondary>
                  <SecondaryNav
                    items={protectionNavItems}
                    item={navProtection}
                    toggle={toggleSecondaryNav}
                    onClick={closeNav}
                  />
                </StyledPrimaryNavItems>
                {renderMobileButtons()}
              </StyledNavBox>
            </div>
          </StyledMenu>
        )}
      </StyledNavBar>
      {isLocaleModalOpen && <LocaleModal isOpen={isLocaleModalOpen} toggle={toggleLocaleModal} />}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  customer: state.customer.data,
  hasLoggedIn: !!state.user.data,
  checkingSession: state.user.checkingSession,
});

export default connect(mapStateToProps)(NavBar);
